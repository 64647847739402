<template>
  <div>
    <top-navigation-component/>
    <div class='p-24'>
      <div class='bea m-auto'>
        <card-element class='py-16'>
          <div class='pt-8 pb-16 text-lg font-bold'>
            <div v-if='profile'>{{ profile.name }}</div>
            <div v-else>{{ $t('common.name') }}</div>
          </div>
          <router-link class='flex justify-between py-16 border-gray-300 border-t'
            :to='{ name: "account" }'>
            <div>{{ $t('pages.settings.6bb0cd5c') }}</div>
            <i class='icon text-gray-700'>edit</i>
          </router-link>
          <router-link class='flex justify-between py-16 border-gray-300 border-t'
            :to='{ name: "cards" }'>
            <div>{{ $t('pages.settings.322f9861') }}</div>
            <i class='icon text-gray-700'>credit_card</i>
          </router-link>
          <div class='flex justify-between pt-16 border-gray-300 border-t cursor-pointer'
            @click='signOut'>
            <div>{{ $t('pages.settings.ac2f6927') }}</div>
            <i class='icon text-gray-700'>exit_to_app</i>
          </div>
        </card-element>
        <card-element class='py-16 mt-24'>
          <router-link class='flex justify-between pb-16'
            :to='{ name: "languages" }'>
            <div>{{ $t('pages.settings.addbbb1b') }}</div>
            <i class='icon text-gray-700'>chevron_right</i>
          </router-link>
          <router-link class='flex justify-between py-16 border-gray-300 border-t'
            :to='{ name: "currencies" }'>
            <div>{{ $t('pages.settings.4dac995d') }}</div>
            <i class='icon text-gray-700'>chevron_right</i>
          </router-link>
          <router-link class='flex justify-between py-16 border-gray-300 border-t'
            :to='{ name: "about-us" }'>
            <div>{{ $t('pages.settings.ec751bd0') }}</div>
            <i class='icon text-gray-700'>chevron_right</i>
          </router-link>
          <router-link class='flex justify-between py-16 border-gray-300 border-t'
            :to='{ name: "privacy-policy" }'>
            <div>{{ $t('pages.settings.b990b653') }}</div>
            <i class='icon text-gray-700'>chevron_right</i>
          </router-link>
          <router-link class='flex justify-between py-16 border-gray-300 border-t'
            :to='{ name: "terms-conditions" }'>
            <div>{{ $t('pages.settings.4da26988') }}</div>
            <i class='icon text-gray-700'>chevron_right</i>
          </router-link>
          <router-link class='flex justify-between py-16 border-gray-300 border-t'
            :to='{ name: "faqs" }'>
            <div>{{ $t('pages.settings.22134d11') }}</div>
            <i class='icon text-gray-700'>chevron_right</i>
          </router-link>
          <router-link class='flex justify-between pt-16 border-gray-300 border-t'
            :to='{ name: "contact-us" }'>
            <div>{{ $t('pages.settings.283512bf') }}</div>
            <i class='icon text-gray-700'>chevron_right</i>
          </router-link>
        </card-element>
      </div>
    </div>
    <bottom-navigation-component/>
  </div>
</template>

<script>
  import BottomNavigation from '@/components/bottom-navigation'
  import TopNavigation from '@/components/top-navigation'
  import store from '@/store'

  export default {
    components: {
      'bottom-navigation-component': BottomNavigation,
      'top-navigation-component': TopNavigation
    },

    computed: {
      profile () {
        return this.$store.state.me.profile
      }
    },

    mounted () {
      this.$store.dispatch('me/fetchProfile')
    },

    methods: {
      async signOut () {
        await this.$store.dispatch('auth/signOut')
        this.$router.push({ name: 'home' })
      }
    },

    async beforeRouteEnter (to, from, next) {
      let user = await store.dispatch('auth/getUser')
      if (!user) {
        next({ name: 'login' })
        return
      }
      next()
    }
  }
</script>

<style lang='scss' scoped>
  .bea {
    max-width: 480px;
  }
</style>
